import { useEffect, useState } from "react";

const MAX_WIDTH = 800;
const MIN_WIDTH = 400;
const MAX_HEIGHT = 350;
const STACKED_HEIGHT = 700;

const useCondenseLayout = () => {
    const [containerHeight, setContainerHeight] = useState(MAX_HEIGHT);
    
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            const shrink = Math.max(0, MAX_WIDTH - width);
            const totalShrink = MAX_WIDTH - MIN_WIDTH;
            const height =
                MAX_HEIGHT + (shrink / totalShrink) *
                (STACKED_HEIGHT - MAX_HEIGHT);
            setContainerHeight(Math.min(STACKED_HEIGHT, height));
        }
        
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    return containerHeight;
}

export default useCondenseLayout;