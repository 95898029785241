import styles from "./Mobile.module.scss";

const Mobile = ({ content }) => {
    const { heading, subheading, description } = content;
    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <div className={styles.titleBlock}>
                    <h1 className={styles.heading}>{heading}</h1>
                </div>
                <div className={styles.contentBlock}>
                    <h2 className={styles.subheading}>{subheading}</h2>
                    <p className={styles.description}>{description}</p>
                </div>
            </div>
        </header>
    )
}

export default Mobile;