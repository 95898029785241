import styles from "./MobileCard.module.scss";

const MobileCard = ({ content }) => {
    const { icon, heading, description } = content;
    
    return (
        <div className={styles.card}>
            <div className={styles.hero}>
                <img src={icon.src} alt={icon.alt} />
            </div>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles.description}>{description}</p>
        </div>
    )
}

export default MobileCard;