import styles from "./Test.module.scss";
import {
    ButtonTypeA as PrimaryCTA, ButtonTypeB as SecondaryCTA 
} from "../../../../../Buttons";
import { setFaqQueue, useSendToSection } from "../../hooks";


const Test = ({ content }) => {
    const {
        hero, heading, description, cta, mirrored
    } = content;
    const sendToSection = useSendToSection();
    return (
        <section className={`${styles.service} ${mirrored ? styles.reverse : ""}`}>
            <div className={`${styles.content} ${mirrored ? styles.reverse : ""}`}>
                <div className={styles.inner}>
                    <h2 className={styles.heading}>{heading}</h2>
                    <p className={styles.description}>{description}</p>
                    <div className={styles.cta}>
                        <PrimaryCTA
                            text={cta.primary.text}
                            target={cta.primary.target}
                            onClick={() => {
                                sendToSection(cta.primary.sectionId, cta.primary.scope)
                            }}
                        />
                        <SecondaryCTA
                            text={cta.secondary.text}
                            target={cta.secondary.target}
                            onClick={() => {
                                setFaqQueue(cta.secondary.faqIds || []);
                                sendToSection(cta.secondary.sectionId, cta.secondary.scope);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={`${styles.hero} ${mirrored ? styles.reverse : ""}`}>
                <img src={hero.src} alt={hero.alt} />
            </div>
        </section>
    )
}

export default Test;