import styles from "./Card.module.scss";
import fallback from "./content";
import { useMediaQuery } from "../../../../../../hooks";
import DesktopCard from "../DesktopCard";
import TabletCard from "../TabletCard";
import MobileCard from "../MobileCard";

const Card = ({ content = fallback }) => {
    const isTablet = useMediaQuery("(max-width: 940px)");
    const isMobile = useMediaQuery("(max-width: 600px)");
    // const { icon, heading, description } = content;
    
    // return (
    //     <div className={styles.card}>
    //         <div className={styles.icon}>
    //             <img src={icon.src} alt={icon.alt} />
    //         </div>
    //         <h2 className={styles.heading}>{heading}</h2>
    //         <p className={styles.description}>{description}</p>
    //     </div>
    // )
    if (isMobile) return <MobileCard content={content} />
    
    if (isTablet) return <TabletCard content={content} />
    
    return <DesktopCard content={content} />
}

export default Card;