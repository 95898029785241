import styles from "./Tablet.module.scss";

const Tablet = ({ content }) => {
    const { heading, subheading, description } = content;
    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <h1 className={styles.heading}>{heading}</h1>
                <h2 className={styles.subheading}>{subheading}</h2>
                <p className={styles.description}>{description}</p>
            </div>
        </header>
    )
}

export default Tablet;