import fallback from "./content";
import { useMediaQuery } from "../../../../hooks";
import Desktop from "./components/Desktop";
import Tablet from "./components/Tablet";
import Compact from "./components/Compact";
import Mobile from "./components/Mobile/Mobile";

const Hero = ({ content = fallback }) => {
    const isTablet = useMediaQuery("(max-width: 990px)");
    const isCompact = useMediaQuery("(max-width: 800px)");
    const isMobile = useMediaQuery("(max-width: 500px)");
    
    if (isMobile) return <Mobile content={content} />
    
    if (isCompact) return <Compact content={content} />
    
    if (isTablet) return <Tablet content={content} />
    
    return <Desktop content={content} />

}

export default Hero;