import styles from "./TabletCard.module.scss";

const TabletCard = ({ content }) => {
    const { icon, heading, description, mirrored } = content;
    
    return (
        <div className={`${styles.card} ${mirrored ? styles.reverse : ""}`}>
            <div className={styles.cardTitle}>
                <div className={styles.icon}>
                    <img src={icon.src} alt={icon.alt} />
                    <h2 className={styles.heading}>{heading}</h2>
                </div>
            </div>
            <div className={`${styles.content} ${mirrored ? styles.reverse : ""}`}>
                <p className={styles.description}>{description}</p>
            </div>
        </div>
    )
}

export default TabletCard;