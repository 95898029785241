import styles from "./Tablet.module.scss";
import {
    ButtonTypeA as PrimaryCTA, ButtonTypeB as SecondaryCTA
} from "../../../../../Buttons";
import { setFaqQueue, useContainerHeight, useSendToSection } from "../../hooks";

const Tablet = ({ content }) => {
    const {
        hero, heading, description, cta, mirrored
    } = content;
    const sendToSection = useSendToSection();
    const containerHeight = useContainerHeight();

    return (
        <section className={`${styles.service} ${mirrored ? styles.reverse : ""}`}>
            <div className={styles.titleBlock}>
                <h2 className={styles.heading}>{heading}</h2>
            </div>
            <div className={styles.container} style={{height: `${containerHeight}px`}}>
                <div className={`${styles.content} ${mirrored ? styles.reverse : ""}`}>
                    <div className={styles.inner}>
                        <p className={styles.description}>{description}</p>
                        <div className={styles.cta}>
                            <PrimaryCTA
                                text={cta.primary.text}
                                target={cta.primary.target}
                                onClick={() => {
                                    sendToSection(
                                        cta.primary.sectionId,
                                        cta.primary.scope
                                    )
                                }}
                            />
                            <SecondaryCTA
                                text={cta.secondary.text}
                                target={cta.secondary.target}
                                onClick={() => {
                                    setFaqQueue(cta.secondary.faqIds || []);
                                    sendToSection(
                                        cta.secondary.sectionId,
                                        cta.secondary.scope
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${styles.hero} ${mirrored ? styles.reverse : ""}`}>
                    <img src={hero.src} alt={hero.alt} />
                </div>
            </div>
        </section>
    )
}

export default Tablet;