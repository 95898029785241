import styles from "./Desktop.module.scss";

const Desktop = ({ content }) => {
    const { heading, subheading, description } = content;
    return (
        <header className={styles.header} id="hero">
            <div className={styles.container}>
                <h1 className={styles.heading}>{heading}</h1>
                <h2 className={styles.subheading}>{subheading}</h2>
                <div className={styles.description}>
                    <div></div>
                    <p>{description}</p>
                </div>
            </div>
        </header>
    )
}

export default Desktop;