import styles from "./Mobile.module.scss";
import {
    ButtonTypeA as PrimaryCTA, ButtonTypeB as SecondaryCTA
} from "../../../../../Buttons";
import { setFaqQueue, useSendToSection } from "../../hooks";

const Mobile = ({ content }) => {
    const {
        hero, heading, description, cta, mirrored
    } = content;
    const sendToSection = useSendToSection();
    
    return (
        <section className={styles.service}>
            <div className={styles.container}>
                <h1 className={styles.heading}>{heading}</h1>
                <div className={styles.hero}>
                    <img src={hero.src} alt={hero.alt} />
                </div>
                <p className={styles.description}>{description}</p>
                <div className={styles.cta}>
                    <PrimaryCTA
                        text={cta.primary.text}
                        target={cta.primary.target}
                        onClick={() => {
                            sendToSection(
                                cta.primary.sectionId,
                                cta.primary.scope
                            )
                        }}
                    />
                    <SecondaryCTA
                        text={cta.secondary.text}
                        target={cta.secondary.target}
                        onClick={() => {
                            setFaqQueue(cta.secondary.faqIds || []);
                            sendToSection(
                                cta.secondary.sectionId,
                                cta.secondary.scope
                            );
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default Mobile;