import { useSectionLayout, useSendToSection } from "./hooks";
import styles from "./ServicePreview.module.scss";
import fallback from "./content";
import { ButtonTypeA as PrimaryCTA } from "../../../Buttons";
import { ButtonTypeB as SecondaryCTA } from "../../../Buttons";
import { useRef } from "react";
import { setFaqQueue } from "./hooks";
import Test from "./components/Test/Test";
import { useMediaQuery } from "../../../../hooks/";
import Tablet from "./components/Tablet/Tablet";
import Mobile from "./components/Mobile/Mobile";

const ServicePreview = ({ content = fallback }) => {
    const isTablet = useMediaQuery("(max-width:990px)");
    const isMobile = useMediaQuery("(max-width:400px)");
    // const {
    //     hero, heading, description, cta, mirrored
    // } = content;
    // const sendToSection = useSendToSection();
    // const sectionRef = useRef();
    // const imageRef = useRef();
    // const innerRef = useRef();
    
    // useSectionLayout(sectionRef, imageRef, innerRef);
    
    // return (
    //     <section ref={sectionRef} className={styles.section}>
    //         <div className={`${styles.service} ${mirrored ? styles.reverse : ""}`}>
    //             <div className={styles.content}>
    //                 <div ref={innerRef} className={styles.inner}>
    //                     <h2 className={styles.heading}>{heading}</h2>
    //                     <p className={styles.description}>{description}</p>
    //                     <div className={styles.cta}>
    //                         <PrimaryCTA
    //                             text={cta.primary.text}
    //                             target={cta.primary.target}
    //                             onClick={() => sendToSection(cta.primary.sectionId, cta.primary.scope)}
    //                             />
    //                         <SecondaryCTA
    //                             text={cta.secondary.text}
    //                             target={cta.secondary.target}
    //                             onClick={() => {
    //                                 setFaqQueue(cta.secondary.faqIds || []);
    //                                 sendToSection(cta.secondary.sectionId, cta.secondary.scope);
    //                             }}
    //                         />
    //                     </div>
    //                 </div>
    //             </div>
    //         <div className={styles.hero}>
    //             <img ref={imageRef} src={hero.src} alt={hero.alt} />
    //         </div>
    //         </div>
    //     </section>
    // )
    if (isMobile) return <Mobile content={content} />
    
    if (isTablet) return <Tablet content={content} />
    
    return <Test content={content} />
}

export default ServicePreview;