import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.jsx'
import "./sass/main.scss";

createRoot(document.getElementById('root')).render(
  <HelmetProvider>
    <Router>
      <StrictMode>
        <App />
      </StrictMode>
    </Router>
  </HelmetProvider>
)
